import React, { useState, useRef, useContext } from "react"
import { Link, navigate, useStaticQuery, graphql } from "gatsby"
import {
  Container,
  Grid,
  Box,
  Breadcrumbs,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import sort from "fast-sort"
import ReactSelect from 'react-select'

import BasicLayout from "../layouts/basic_layout"
import Head from "../components/head"

import styles from "./index.module.scss"
import advancedstyles from "./advanced-search.module.scss"

import { SearchContextDispatch } from "../context/search-context/search-context"
import * as actionTypes from "../context/actionTypes"

const AdvancedSearchPage = () => {
  const GET_CSV_DATA_QUERY = graphql`
    query getLanguageAndLibraryTypes {
      allLanguageMappingCsv {
        nodes {
          key
          hun
          eng
        }
      }
      allLibraryMappingCsv {
        edges {
          node {
            category
          }
        }
      }
      allDoctypeMappingCsv {
        nodes {
          hun
        }
      }
    }
  `
  const getDatasFromCsv = useStaticQuery(GET_CSV_DATA_QUERY)
  const {
    allLanguageMappingCsv,
    allLibraryMappingCsv,
    allDoctypeMappingCsv,
  } = getDatasFromCsv

  const pageTitle = "Részletes keresés"

  const dispatch = useContext(SearchContextDispatch)

  const publisherInputElement = useRef(null)
  const authorInputElement = useRef(null)
  const titleInputElement = useRef(null)
  const possessorInputElement = useRef(null)
  const publishLocationInputElement = useRef(null)
  const referenceInputElement = useRef(null)
  const publishYearInputElement = useRef(null)
  const stockRefInputElement = useRef(null)
  const libraryCodeInputElement = useRef(null)
  const subjectInputElement = useRef(null)

  const [languageCode, setLanguageCode] = useState({value: 'all', label: 'Mind'})
  const [libraryType, setLibraryType] = useState({value: "all", label: 'Mind'})
  const [docType, setDocType] = useState({value: 'all', label: 'Mind'})

  const [afterYear, setAfterYear] = useState(false)
  const [previousYear, setPreviousYear] = useState(false)
  const [fromYear, setFromYear] = useState("")
  const [toYear, setToYear] = useState("")

  const [errors, setErrors] = useState({
    fromYear: false,
    toYear: false,
    publishYear: false,
  })

  const onAfterCheckboxChangedHandler = e => {
    if (afterYear) {
      setAfterYear(false)
    } else {
      setAfterYear(e.target.checked)
      setPreviousYear(!e.target.checked)
    }
  }

  const onPreviousCheckboxChangedHandler = e => {
    if (previousYear) {
      setPreviousYear(false)
    } else {
      setAfterYear(!e.target.checked)
      setPreviousYear(e.target.checked)
    }
  }

  const urlFormer = urlData => {
    let formUrl = `/element_list?`

    Object.keys(urlData).map(key => {
      if (key === "librarytype" || key === "type" || key === "languagecode") {
        if (urlData[key] === "all") return

        return (formUrl += `${key}=${urlData[key]}&`)
      } else if (urlData[key] === "" || !urlData[key]) return

      return (formUrl += `${key}=${urlData[key]}&`)
    })

    formUrl = formUrl.slice(0, -1)

    return formUrl
  }

  const onSearchButtonClicked = e => {
    e.preventDefault()

    const publisherVal = publisherInputElement.current.value
    const authorVal = authorInputElement.current.value
    const titleVal = titleInputElement.current.value
    const possessorVal = possessorInputElement.current.value
    const publishLocationVal = publishLocationInputElement.current.value
    const referenceVal = referenceInputElement.current.value
    const publishYearVal = publishYearInputElement.current.value
    const stockRefVal = stockRefInputElement.current.value
    const libraryCodeVal = libraryCodeInputElement.current.value
    const subjectVal = subjectInputElement.current.value

    //Validation
    if (fromYear !== "") {
      if (toYear === "") return setErrors({ ...errors, toYear: true })
      else if (parseInt(toYear) < parseInt(fromYear))
        return setErrors({ ...errors, toYear: true })
      else setErrors({ ...errors, fromYear: false, toYear: false })
    } else if (toYear !== "") {
      if (fromYear === "") return setErrors({ ...errors, fromYear: true })
      else if (parseInt(fromYear) > parseInt(toYear))
        return setErrors({ ...errors, toYear: true })
      else setErrors({ ...errors, fromYear: false, toYear: false })
    }

    if (publishYearVal === "") {
      if (previousYear || afterYear)
        return setErrors({ ...errors, publishYear: true })
    }

    if (publishYearVal !== "") {
      if (fromYear !== "" || toYear !== "")
        return setErrors({
          ...errors,
          fromYear: true,
          toYear: true,
          publishYear: true,
        })
    }

    const regex = new RegExp(/^[0-9]*$/)
    if (!regex.test(publishYearVal)) {
      return setErrors({ ...errors, publishYear: true })
    }

    setErrors({ ...errors, fromYear: false, toYear: false, publishYear: false })

    const urlData = {
      publisher: publisherVal,
      author: authorVal,
      title: titleVal,
      possessor: possessorVal,
      placeOfAppearance: publishLocationVal,
      reference: referenceVal,
      warehouseSign: stockRefVal,
      libraryCode: libraryCodeVal,
      subject: subjectVal,
      publishyear: publishYearVal,
      afteryear: afterYear,
      previousyear: previousYear,
      fromyear: fromYear,
      toyear: toYear,
      librarytype: libraryType.value,
      type: docType.value,
      languagecode: languageCode.value,
    }

    const formUrl = urlFormer(urlData)

    dispatch({
      type: actionTypes.ON_SET_KEYWORD_FOR_BASIC_SEARCH,
      keyword: "",
    })

    navigate(formUrl)
  }

  const langMenuItems = allLanguageMappingCsv.nodes.map(node => {
    const output = node.hun.trim() !== "" ? node.hun : node.eng

    return {
      label: output.toLowerCase(),
      value: node.key,
    }
  })

  sort(langMenuItems).asc(lang => lang.output)
  langMenuItems.unshift({
    value: 'all',
    label: "Mind"
  })


  const libraryTypesGroupBy = []
  allLibraryMappingCsv.edges.map(node => {
    const match = libraryTypesGroupBy.find(type => type.value === node.node.category)
    console.log(match)
    if (!match) {
      return libraryTypesGroupBy.push({value: node.node.category, label: node.node.category})
    }

    return null
  })

  libraryTypesGroupBy.unshift({
    value: 'all',
    label: 'Mind'
  })

  const doctypeOptions = allDoctypeMappingCsv.nodes.map(node => {
    return {value: node.hun, label: node.hun}
  })

  doctypeOptions.unshift({
    value: 'all',
    label: 'Mind'
  })

  const onLangugageSelectChanged = change => {
    setLanguageCode(change)
  }

  return (
    <BasicLayout>
      <Head title={`${pageTitle}`} />
      <Grid
        className={styles.mainContainer}
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Box className={styles.topContainerSubpage} component="section">
          <Container
            className={styles.topContainerInnerSubpage}
            maxWidth={"lg"}
          >
            <Box />
          </Container>
        </Box>
        <Box
          className={styles.pageContentContainerSubpageWhite}
          component="section"
        >
          <Container maxWidth={"lg"}>
            <Box className={styles.breadcrumbContainerSubpage} component="div">
              <Breadcrumbs separator="/" aria-label="breadcrumb">
                <Link hinputRef="/" className={styles.breadcrumbMenuLink}>
                  Főoldal
                </Link>
                <span className={styles.breadcrumbMenuNow}>
                  {`${pageTitle}`}
                </span>
              </Breadcrumbs>
            </Box>
            <div className={styles.textContentContainer}>
              <h3>Részletes keresés</h3>
              <p>Keresési lehetőségek</p>
            </div>
            <Grid container className={advancedstyles.searchFields}>
              {/* 1 */}
              <Grid item sm={6} xs={12} className={advancedstyles.searchBlock}>
                <span className={advancedstyles.lineLabel}>Cím</span>
                <form onSubmit={onSearchButtonClicked} className={"textFieldContainer"}>
                  <TextField
                    id="titleSearch"
                    className={"textField"}
                    margin="none"
                    variant="outlined"
                    inputRef={titleInputElement}
                  />
                </form>
              </Grid>
              {/* 2 */}
              <Grid item sm={6} xs={12} className={advancedstyles.searchBlock}>
                <span className={advancedstyles.lineLabel}>Szerző</span>
                <form onSubmit={onSearchButtonClicked} className={"textFieldContainer"}>
                  <TextField
                    id="AuthorSearch"
                    className={"textField"}
                    margin="none"
                    variant="outlined"
                    inputRef={authorInputElement}
                  />
                </form>
              </Grid>
              {/* 3 */}
              <Grid item sm={6} xs={12} className={advancedstyles.searchBlock}>
                <span className={advancedstyles.lineLabel}>
                  Megjelenés helye
                </span>
                <form onSubmit={onSearchButtonClicked} className={"textFieldContainer"}>
                  <TextField
                    id="placeOfAppearanceSearch"
                    className={"textField"}
                    margin="none"
                    variant="outlined"
                    inputRef={publishLocationInputElement}
                  />
                </form>
              </Grid>
              {/* 4 */}
              <Grid item sm={6} xs={12} className={advancedstyles.searchBlock}>
                <span className={advancedstyles.lineLabel}>Megjelenés éve</span>
                <Grid container className={advancedstyles.searchBlockSubLine}>
                  <Grid item md={6} sm={12} xs={12}>
                    <form onSubmit={onSearchButtonClicked} className={"textFieldContainer"}>
                      {errors.publishYear ? (
                        <TextField
                          error
                          id="placeOfAppearanceSearch"
                          className={"textField"}
                          margin="none"
                          variant="outlined"
                          inputRef={publishYearInputElement}
                        />
                      ) : (
                        <TextField
                          id="placeOfAppearanceSearch"
                          className={"textField"}
                          margin="none"
                          variant="outlined"
                          inputRef={publishYearInputElement}
                        />
                      )}
                    </form>
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="default"
                          className={"checkbox"}
                          checked={previousYear}
                          onChange={onPreviousCheckboxChangedHandler}
                        />
                      }
                      label={<div className={"checkboxLabel"}>előtt</div>}
                      className={"checkboxContainerLabel"}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="default"
                          className={"checkbox"}
                          checked={afterYear}
                          onChange={onAfterCheckboxChangedHandler}
                        />
                      }
                      label={<div className={"checkboxLabel"}>után</div>}
                      className={"checkboxContainerLabel"}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* 4 */}
              <Grid item sm={6} xs={12} className={advancedstyles.searchBlock}>
                <Grid container>
                  <Grid item xs={5}>
                    <span className={advancedstyles.lineLabel}>Évtől</span>
                    <form onSubmit={onSearchButtonClicked} className={"textFieldContainer"}>
                      {errors.fromYear ? (
                        <TextField
                          error
                          id="outlined-number"
                          type="number"
                          margin="none"
                          variant="outlined"
                          className={"textField"}
                          value={fromYear}
                          onChange={e => setFromYear(e.target.value)}
                        />
                      ) : (
                        <TextField
                          id="outlined-number"
                          type="number"
                          margin="none"
                          variant="outlined"
                          className={"textField"}
                          value={fromYear}
                          onChange={e => setFromYear(e.target.value)}
                        />
                      )}
                    </form>
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={5} >
                    <span className={advancedstyles.lineLabel}>Évig</span>
                    <form onSubmit={onSearchButtonClicked} className={"textFieldContainer"}>
                      {errors.toYear ? (
                        <TextField
                          error
                          id="outlined-number"
                          type="number"
                          margin="none"
                          variant="outlined"
                          className={"textField"}
                          value={toYear}
                          onChange={e => setToYear(e.target.value)}
                        />
                      ) : (
                        <TextField
                          id="outlined-number"
                          type="number"
                          margin="none"
                          variant="outlined"
                          className={"textField"}
                          value={toYear}
                          onChange={e => setToYear(e.target.value)}
                        />
                      )}
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              {/* 5 */}
              <Grid item sm={6} xs={12} className={advancedstyles.searchBlock}>
                <span className={advancedstyles.lineLabel}>
                  Nyomdász / Kiadó
                </span>
                <form onSubmit={onSearchButtonClicked} className={"textFieldContainer"}>
                  <TextField
                    id="PrinerSearch"
                    className={"textField"}
                    margin="none"
                    variant="outlined"
                    inputRef={publisherInputElement}
                  />
                </form>
              </Grid>
              {/* 6 */}
              <Grid item sm={6} xs={12} className={advancedstyles.searchBlock}>
                <span className={advancedstyles.lineLabel}>Tárgyszó</span>
                <form onSubmit={onSearchButtonClicked} className={"textFieldContainer"}>
                  <TextField
                    id="placeOfAppearanceSearch"
                    className={"textField"}
                    margin="none"
                    variant="outlined"
                    inputRef={subjectInputElement}
                  />
                </form>
              </Grid>
              {/* 7 */}
              <Grid item sm={6} xs={12} className={advancedstyles.searchBlock}>
                <span className={advancedstyles.lineLabel}>Formátum</span>
                <form onSubmit={onSearchButtonClicked} className={"selectorFieldContainer"}>
                  <ReactSelect
                    options={doctypeOptions}
                    value={docType}
                    onChange={c => setDocType(c)}
                    isSearchable={true}
                  />
                </form>
              </Grid>
              
              {/* 8 */}
              <Grid item sm={6} xs={12} className={advancedstyles.searchBlock}>
                <span className={advancedstyles.lineLabel}>Possessor</span>
                <form onSubmit={onSearchButtonClicked} className={"textFieldContainer"}>
                  <TextField
                    id="possessorSearch"
                    className={"textField"}
                    margin="none"
                    variant="outlined"
                    inputRef={possessorInputElement}
                  />
                </form>
              </Grid>
              {/* 9 */}
              <Grid item sm={6} xs={12} className={advancedstyles.searchBlock}>
                <span className={advancedstyles.lineLabel}>Hivatkozás</span>
                <form onSubmit={onSearchButtonClicked} className={"textFieldContainer"}>
                  <TextField
                      id="placeOfAppearanceSearch"
                      className={"textField"}
                      margin="none"
                      variant="outlined"
                      inputRef={referenceInputElement}
                  />
                </form>
              </Grid>
              {/* 10 */}
              <Grid item sm={6} xs={12} className={advancedstyles.searchBlock}>
                <span className={advancedstyles.lineLabel}>Könyvtár típus</span>
                <form onSubmit={onSearchButtonClicked} className={"selectorFieldContainer"}>
                  <ReactSelect
                    options={libraryTypesGroupBy}
                    value={libraryType}
                    onChange={c => setLibraryType(c)}
                    isSearchable={true}
                  />
                </form>
              </Grid>
              {/* 11 */}
              <Grid item sm={6} xs={12} className={advancedstyles.searchBlock}>
                <span className={advancedstyles.lineLabel}>Könyvtár (kód)</span>
                <form onSubmit={onSearchButtonClicked} className={"textFieldContainer"}>
                  <TextField
                    id="placeOfAppearanceSearch"
                    className={"textField"}
                    margin="none"
                    variant="outlined"
                    inputRef={libraryCodeInputElement}
                  />
                </form>
              </Grid>
              <Grid item sm={6} xs={12} className={advancedstyles.searchBlock}>
                <span className={advancedstyles.lineLabel}>Nyelv</span>
                <form onSubmit={onSearchButtonClicked} className={"selectorFieldContainer"}>
                  <ReactSelect
                    options={langMenuItems}
                    value={languageCode}
                    onChange={onLangugageSelectChanged}
                    isSearchable={true}
                  />
                </form>
              </Grid>           
              
              <Grid item sm={6} xs={12} className={advancedstyles.searchBlock}>
                <span className={advancedstyles.lineLabel}>Raktári jelzet</span>
                <form onSubmit={onSearchButtonClicked} className={"textFieldContainer"}>
                  <TextField
                    id="placeOfAppearanceSearch"
                    className={"textField"}
                    margin="none"
                    variant="outlined"
                    inputRef={stockRefInputElement}
                  />
                </form>
              </Grid>                          
              
              <Grid
                container
                sm={12}
                xs={12}
                className={advancedstyles.searchBlockButton}
                justify="center"
                alignItems="center"
              >
                <Link
                  onClick={onSearchButtonClicked}
                  className={advancedstyles.searchButton}
                >
                  Keresés
                </Link>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Grid>
    </BasicLayout>
  )
}
export default AdvancedSearchPage
